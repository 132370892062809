<template>
  <v-container
        tag="section"
    >
        <v-row
            justify="center"
        >
            <v-col
                cols="12"
                lg="9"
                xl="7"
                class="block-media-lk"
            >
                <base-material-card
                    color="warning"
                    class="px-sm-8 px-md-16 px-2 py-3"
                >
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Рассылка
                        </div>

                        <div class="subtitle-1 font-weight-light">
                            Всплывающее окно в "Мои заявки"
                        </div>
                    </template>

                    <v-card-text
                        class="mb-10 card-media-lk"
                    >
                      <p class="mt-5">
                          Обновить рассылку:
                      </p>
                      <vue-editor
                          v-model="notificationData.text"
                          :editor-toolbar="customToolbar1"
                      />
                      <!-- <div class="invalid-feedback" v-if="errors.text">{{ errors.text[0] }}</div> -->

                      <v-btn
                          color="success"
                          class="mr-0 mt-5"
                          @click="saveData"
                      >
                          Сохранить данные
                      </v-btn>
                      <v-progress-linear
                          :active="loading"
                          :indeterminate="loading"
                          absolute
                          bottom
                          color="success accent-4"
                      ></v-progress-linear>
                    </v-card-text>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import * as notificationService from '../../../services/notification_service'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: "Notification",
  data: () => ({
    customToolbar1: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [{ align: "" }, { align: "center" }, { align: "right" }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ color: [] }, { background: [] }],
        ["link"]
    ],
    loading: false
  }),
  computed: {
    ...mapGetters({
      notification: 'notification/notification',      
    }),
    notificationData() {
      return this.notification
    },
  },
  created () {
    this.initialize()
  },
  methods: {
      ...mapActions({
          addNotification: 'application/addNotification',
          loadNotification: 'notification/loadNotification',
          updateNotification: 'notification/updateNotification',
      }),
      initialize () {
        this.loadNotification()
      },
      saveData() {
        this.loading = true
        this.notificationData.version = this.notificationData.version + 1
        notificationService.updateNotification(this.notificationData.id, this.notificationData)
            .then((response) => {
                this.updateNotification(response.data)
                if (response.data && response.data.success) {
                    this.errors = {}
                    this.loading = false
                    this.addNotification({
                        show: true,
                        text: response.data.message,
                        color: 'success'
                    })
                    this.initialize()
                }
            })
            .catch((error) => {
                switch (error.response.status) {
                    case 422:
                        this.loading = false
                        this.addNotification({
                            show: true,
                            text: error.response.data.message,
                            color: 'error'
                        })
                        this.errors = error.response.data.errors
                        break
                    case 500:
                        this.loading = false
                        this.addNotification({
                            show: true,
                            text: error.response.data.message,
                            color: 'error'
                        })
                        this.errors = error.response.data.errors
                        break
                    default:
                        this.loading = false
                        this.addNotification({
                            show: true,
                            text: error.response.data.message,
                            color: 'error'
                        })
                        this.errors = error.response.data.errors
                        break
                }
            })
        }
  },
}
</script>

<style lang="sass">
.v-chip.v-size--small
  height: 26px

.v-chip__content
  font-size: 13px
</style>
